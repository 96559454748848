import './index.scss'
import matrix from './img/matrix.svg'
import dimensions from './img/dimensions.svg'
import angle from './img/angle.svg'
import weight from './img/weight.svg'
import fasteners from './img/fasteners.svg'
import warranty from './img/warranty.svg'

const Advantages = () => {
  return (
    <section className="advantages" id="advantages">
      <div className="container">
        <p className="advantages__text">
          Презентуємо перший тепловізор для ударних дронів українського виробництва <b>Seek UAV 256</b>. Він має високу якість зображення завдяки нашому власному алгоритму калібровки, а також можливість ручного керування.
        </p>
        <ul className="advantages__list">
          <li className="advantages__list-item">
            <img src={matrix} alt="Matrix" />
            <p>Матриця</p>
            <p>256 х 192, 25гц</p>
          </li>
          <li className="advantages__list-item">
            <img src={dimensions} alt="Dimensions" />
            <p>Габарити</p>
            <p>25 х 22 х 11 мм</p>
          </li>
          <li className="advantages__list-item">
            <img src={angle} alt="Angle" />
            <p>Кут огляду</p>
            <p>56 х 42 °</p>
          </li>
          <li className="advantages__list-item">
            <img src={weight} alt="Weight" />
            <p>Вага</p>
            <p>10 грам</p>
          </li>
          <li className="advantages__list-item">
            <img src={fasteners} alt="Fasteners" />
            <p>Кріплення для рами</p>
            <p>19 мм</p>
          </li>
          <li className="advantages__list-item">
            <img src={warranty} alt="Warranty" />
            <p>Офіційна</p>
            <p>гарантія та сервіс</p>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Advantages