import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductOne = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} />
      <Description>
        <a
          href="https://docs.google.com/document/d/192gS4cepzQE3uAxFIKi887x_LnHlaMeu/edit?usp=sharing&ouid=111034180304603509934&rtpof=true&sd=true"
          target="_blank"
        >
          Технічні характеристики тепловізору Seek UAV 256
        </a>
        <p>
          Тепловізор для ударних FPV-дронів Seek UAV 256 має максимально плавне зображення без постійних фрізів та затримок завдяки нашому алгоритму калібровки. Тепловізор легкий та компактний, живиться від широкого діапазону напруги 5-30 Вольт та має класичний CVBS відеовихід.
        </p>
        <p>
          Всі компоненти, крім матриці та лінзи, тепловізору розроблені та виготовляються в Україні з найкращих комплектуючих.  Крім того ми надаємо офіційну гарантію на всі тепловізори та забезпечуємо їх пожиттєве сервісне обслуговування.
        </p>
      </Description>
    </div>
  )
}

export default ProductOne