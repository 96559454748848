import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductFour = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} />
      <Description>
        <a
          href="https://docs.google.com/document/d/1qkdA2jSAiV8xG6LJGN3gEjtMmsND4ZQ6/edit?usp=drive_link&ouid=111034180304603509934&rtpof=true&sd=true"
          target="_blank"
        >
          Технічні характеристики Seek UAV Mini
        </a>
        <p>
          Підвісний тепловізійний комплекс Seek UAV Mini 256 обладнаний нашим тепловізором Seek UAV 256 та призначений для установки на звичайні дрони моделей DJI Mavic, AUTEL та подібні. Він дає змогу виконувати розвідувальні місії та місії коригування вогню у темний час доби. Час польоту дрону з комплексом на борту зменшується всього на 2-5 хвилин, в залежності від моделі.
        </p>
        <div>
          <p>Комплекс Seek UAV Mini має:</p>
          <ul>
            <li>Можливість комплектації тепловізором з різними характеристиками.</li>
            <li>Мінімальну вагу всього 150 грамів та власну систему живлення.</li>
            <li>Передачу відео на поширеній частоті 5.8Ghz з опцією кодування сигналу.</li>
            <li>Швидке кріплення на дрон всього за 20 секунд, з-за допомогою липучки.</li>
          </ul>
        </div>
        <div>
          <p>Комплектація:</p>
          <ul>
            <li>комплекс Seek UAV Mini;</li>
            <li>2 батареї;</li>
            <li>зарядний пристрій;</li>
          </ul>
        </div>
      </Description>
    </div>
  )
}

export default ProductFour